<template>
  <div class="field-detail">
    <van-nav-bar
      :title="position"
      left-text="返回"
      left-arrow
      @click-left="onNavClick"
    />
    <div class="field-detail-wrap">
      <!-- 天气实况 -->
      <div class="live-weather">
        <my-live-weather :lngLat="lngLat" />
      </div>
      <!-- 天气预报和土壤监测 -->
      <div class="forcast-and-soilTest">
        <div class="forcastAndSoilTest-wrap">
          <van-tabs type="card" color="#001F90">
            <van-tab title="气象预报">
              <div class="forecast-wrap">
                <my-forecast-weather :lat="lngLat.lat" :lng="lngLat.lng" />
              </div>
            </van-tab>
            <van-tab title="土壤检测">
              <div class="survey-wrap">
                <my-soil-detect :landId="id" />
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </div>

      <!--农田相关的信息 改版-->
      <div class="field-info">
        <div class="field-img">
          <img
            v-if="fieldDetail.imageUrl"
            :src="fieldDetail.imageUrl"
            style="width: 100%; height: 100%; background: #ddd"
          />
          <div v-else style="width: 100%; height: 100%; background: #ddd"></div>
        </div>
        <div class="field-info-detail">
          <div class="detail-item">
            <div class="name">{{ fieldDetail && fieldDetail.name }}</div>
            <div class="contact">
              {{
                `${fieldDetail && fieldDetail.province_name}-${
                  fieldDetail && fieldDetail.city_name
                }-${fieldDetail && fieldDetail.county_name}`
              }}
            </div>
            <div class="detail-item-carbon">
              <img src="../../assets/image/Field/footprint.png" alt="" />
            </div>
          </div>
          <div class="detail-item">
            <div class="area-num">{{ fieldDetail && fieldDetail.area }}亩</div>
            <div class="location">{{ cropNum }}种作物</div>
            <van-button type="primary" size="mini" @click="toaddCorp(fieldDetail.id)">添加作物</van-button>
          </div>
        </div>
      </div>
      <!-- 作物种类 -->
      <van-collapse accordion v-model="activeNames">
        <!-- <hr> -->
        <van-collapse-item
          class="crop-list"
          v-for="(item, name, index) in landCrop"
          :name="index"
          :key="index"
        >
          <template #title>
            <div
              class="crop-item"
              @click.stop="collapseToggle(index, name, item[0].crop_id)"
            >
              <div class="crop-img">
                <img
                  v-if="item[0].cropImage"
                  :src="item[0].cropImage"
                  style="width: 100%; height: 100%; background: #ddd"
                />
                <div
                  v-else
                  style="width: 100%; height: 100%; background: #ddd"
                ></div>
              </div>
              <div class="crop-info">
                <div class="crop-total">
                  {{ cropList.length }}个品种
                  <span>，{{ area }}亩</span>
                </div>
                <div class="corp-detail">
                  <div
                    class="crop-detail"
                    v-for="(crop, index) in item"
                    :key="index"
                  >
                    <div>{{ crop.crop_type_name }}</div>
                    <div>{{ crop.area }}亩</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="my-collapse-content">
            <div v-if="loading" class="requesting">数据加载中...</div>
            <div else class="hadRequest">
              <div class="wrap" v-if="list && list.length > 0">
                <div class="collapse-content-item">
                  <div class="cropList">
                    <div
                      class="crop"
                      v-for="(item, index) in list"
                      @click.stop="toCropDetail(item.id)"
                      :key="index"
                    >
                      <div class="crop-left">
                        <img v-if="item.cropImage" :src="item.cropImage" />
                        <div v-else></div>
                      </div>
                      <div class="crop-right">
                        <div class="info">
                          <div class="name">{{ item.crop_name }}</div>
                          <div class="typeName">
                            {{ item.crop_type_name }}
                          </div>
                          <div class="area">{{ item.area }}亩</div>
                        </div>
                        <div class="date-info">
                          <div>
                            <img
                              src="../../assets/image/Field/组 3.png"
                              alt=""
                            />{{ item.plant_time | dateFormat
                            }}<span>种后{{ item.after_plant_days }}天</span>
                          </div>
                          <div style="margin-left: 10px">
                            <img
                              src="../../assets/image/Field/组 2.png"
                              alt=""
                            />{{ item.expect_harvest_time | dateFormat
                            }}<span>{{ item.to_harvest_days }}</span
                            >天后
                          </div>
                        </div>
                        <div v-if="item.currentCropLife" class="day-info">
                          <img src="../../assets/image/Field/组 4.png" alt="" />
                          <span
                            v-for="items in item.currentCropLife.phenologyOperList.slice(
                              0,
                              3
                            )"
                            :key="items.id"
                            >{{ items.name }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                style="padding: 20px 0; font-size: 20px; text-align: center"
              >
                此地块暂时没有作物，请先添加作物
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import { NavBar, Tab, Tabs, Button, Collapse, CollapseItem, Toast } from "vant";
import MyLiveWeather from "./liveWeather.vue";
import MyForecastWeather from "@/components/home/MaxAndMinTemp.vue";
import MySoilDetect from "./cropDetail/soilDetect.vue";
import {
  getFieldById,
  getFieldByIds,
} from "@/service/serverPage/fieldDetail.js";
import { getFieldList } from "../../service/serverPage/myField";
export default {
  name: "FieldDetail",
  components: {
    "van-nav-bar": NavBar,
    "van-tabs": Tabs,
    "van-tab": Tab,
    "my-live-weather": MyLiveWeather,
    "my-forecast-weather": MyForecastWeather,
    "my-soil-detect": MySoilDetect,
    "van-button": Button,
    "van-collapse": Collapse,
    "van-collapse-item": CollapseItem,
  },
  data() {
    return {
      activeTab: "zzgh",
      fieldDetail: {},
      lngLat: {},
      landCrop: {},
      cropCarbon: {},
      activeNames: -1,
      cropId: "",
      cropList: [],
      list: [],
      loading: false,
    };
  },
  computed: {
    position: function () {
      return this.fieldDetail.city_name && this.fieldDetail.county_name
        ? `${this.fieldDetail.city_name}-${this.fieldDetail.county_name}`
        : "";
    },
    cropNum() {
      return Object.keys(this.landCrop).length;
    },
    area: function () {
      let areas = 0;
      for (let i = 0; i < this.cropList.length; i++) {
        areas += this.cropList[i].area;
      }
      return areas;
    },
  },
  filters: {
    dateFormat(date, msg) {
      const dt = new Date(date);
      const m = (dt.getMonth() + 1 + "").padStart(2);
      const d = (dt.getDate() + "").padStart(2);
      return `${m}月${d}日`;
    },
  },
  props: ["id"],
  methods: {
    toCropDetail(id) {
      this.$router.push({ path: `/server/cropDetail/${id}` });
    },
    toaddCorp(fieldId) {
      this.$router.push({ path: `/addCrop/${fieldId}` });
    },
    onNavClick() {
      this.$router.go(-1);
    },
    amapGeocoder(position) {
      return new Promise((resolve, reject) => {
        AMap.plugin("AMap.Geocoder", function () {
          var geocoder = new AMap.Geocoder();
          geocoder.getLocation(position, function (status, result) {
            if (status === "complete" && result.geocodes.length) {
              var lnglat = result.geocodes[0].location;
              resolve(lnglat);
            } else {
              reject(new Error("根据地址查询位置失败"));
            }
          });
        });
      });
    },
    init() {
      getFieldList().then((res) => {
        this.list = res.data;
      });
    },
    initById() {
      getFieldByIds(this.cropId, this.id).then((res) => {
        this.cropList = res.data;
      });
    },
    collapseToggle(index, name, fieldId) {
      if (!this.cropList[index].cropList) {
        const myToast = Toast.loading({
          message: "加载中...",
          forbidClick: true,
          duration: 0,
        });
        getFieldByIds(fieldId, this.id).then((res) => {
          if (res.code === 200) {
            this.$set(this.cropList[index], "cropList", res.data);
            console.log(res);
            this.list = res.data;
            this.$nextTick(() => {
              this.activeNames = index;
              myToast.clear();
            });
          }
        });
        getFieldByIds(fieldId).then((res) => {
          this.OperList = res.data;
        });
      } else {
        if (this.activeNames === index) {
          this.activeNames = -1;
        } else {
          this.activeNames = index;
        }
      }
    },
  },
  mounted() {
    this.init();
    getFieldById(this.id).then((res) => {
      this.fieldDetail = res.data.land;
      this.landCrop = res.data.landCrop;
      this.cropCarbon = res.data.cropCarbon;
      const position = `${res.data.land.province_name}${res.data.land.city_name}${res.data.land.county_name}`;
      this.amapGeocoder(position).then((res) => {
        this.lngLat = {
          lat: res.lat,
          lng: res.lng,
        };
      });
    });
    this.initById();
  },
};
</script>

<style lang="scss" scoped>
.field-detail {
  .my-collapse-content {
    color: #333;
    .wrap {
      .collapse-content-item {
        display: flex;
        margin: 2px 0;
        .cropList {
          width: 100%;
          .crop {
            display: flex;
            border: 1px solid #ddd;
            align-items: center;
            border-radius: 4px;
            height: 80px;
            padding: 2px;
            & + .crop {
              margin: 5px 0;
            }
            .crop-left {
              flex: 0 0 80px;
              img {
                width: 100%;
                max-height: 76px;
              }
              div {
                background: lightblue;
              }
            }
            .crop-right {
              height: 100%;
              display: flex;
              flex: 1 0 auto;
              flex-direction: column;
              justify-content: space-around;
              padding-left: 10px;
              .info {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
              }
              .lifeAndCondition {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .life {
                  margin-right: 14px;
                }
                .carbon {
                  background: #d0e17d;
                  padding: 0 8px;
                }
              }
              .date-info {
                font-size: 2.2vw;
                display: flex;
                div {
                  display: flex;
                  align-items: center;
                  line-height: 20px;
                  div:nth-child(2) {
                    margin-left: 10px;
                  }
                  span {
                    margin-left: 5px;
                  }
                  img {
                    margin-right: 3px;
                    width: 16px;
                    height: 16px;
                  }
                }
              }
              .day-info {
                width: 67vw;
                display: flex;
                align-items: center;
                overflow: hidden; //超出文本隐藏
                white-space: nowrap; //不换行，只显示一行
                text-overflow: ellipsis; //超出部分省略号显示
                img {
                  width: 16px;
                  height: 16px;
                }
                span {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  color: #333;
  box-sizing: border-box;
  font-size: 14px;
  .field-detail-wrap {
    margin: 0 10px 20px;
    .forcast-and-soilTest {
      margin-top: 14px;
      .forecast-wrap {
        margin-top: 20px;
      }
      .survey-wrap {
        margin-top: 20px;
        font-size: 22px;
        height: 200px;
        text-align: center;
      }
    }
    .field-info {
      width: 94vw;
      margin-top: 20px;
      display: flex;
      height: 60px;
      background: #edfafd;
      .field-img {
        flex: 0 0 80px;
        height: 100%;
      }
      .field-info-detail {
        display: flex;
        margin-left: 14px;
        flex-direction: column;
        justify-content: space-around;
        .detail-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .name {
            width: 100px;
            font-weight: 600;
            overflow: hidden; //超出文本隐藏
            white-space: nowrap; //不换行，只显示一行
            text-overflow: ellipsis; //超出部分省略号显示
            -o-text-overflow: ellipsis;
          }
          .contact {
            width: 20vw;
            margin-left: 26px;
            overflow: hidden; //超出文本隐藏
            white-space: nowrap; //不换行，只显示一行
            text-overflow: ellipsis; //超出部分省略号显示
            -o-text-overflow: ellipsis;
          }
          .detail-item-carbon {
            // margin-left: 32px;
            padding: 0 5px;
            img {
              width: 20px;
            }
          }
          div + div {
            margin-left: 20px;
          }
        }
      }
    }

    .crop-list {
      margin: 10px 0;
      border-bottom: 1px solid #ddd;
      .area-total {
        margin-bottom: 20px;
      }
      .crop-item {
        height: 60px;
        display: flex;
        padding: 6px;
        // border: 1px solid #ddd;
        & + .crop-item {
          margin-top: 20px;
        }
        .crop-img {
          flex: 0 0 80px;
        }
        .crop-info {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          justify-content: space-around;
          .corp-detail {
            text-align: left;
            display: flex;
            flex-wrap: wrap;
            .crop-detail {
              display: flex;
              div {
                margin-right: 5px;
              }
            }
          }
          .crop-total {
            display: flex;
            .crop-total-carbon {
              background: #d0e17d;
              padding: 0 8px;
              margin-left: 40px;
            }
          }
          .crop-detail {
            span + span {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="css" scoped>
.field-detail .forcast-and-soilTest .van-tabs__nav--card {
  margin: 0 auto !important;
  width: 40%;
}

.field-detail .field-info .van-tabs__nav--card {
  margin: 0 !important;
  width: 60%;
}
>>> .van-cell  {
  padding: 0 !important;
}
>>> .van-collapse-item__content{
  padding: 0;
}
</style>
