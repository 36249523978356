import server from "../axios.config.js";

// 田地列表
export const getFieldList = function() {
  return server({
    method: "get",
    baseURL: "http://110.87.103.59:18086/farmfriend",
    url: "/land/selectList"
  });
};

/****
 * 田地合同状态操作接口
 * contract: 合同参数 1：已签； 0：未签
 * id: 农田的id
 * ****/
export const postFieldContract = function(contract, id) {
  const data = new FormData();
  data.append("contract", contract);
  data.append("id", id);
  return server({
    method: "post",
    baseURL: "http://110.87.103.59:18086/farmfriend",
    url: "/land/update",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
};

/****
 * 田地调研状态操作接口
 * contract: 调研参数 1：已签； 0：未签
 * id: 农田的id
 * ****/
export const postFieldResearch = function(survey, id) {
  const data = new FormData();
  data.append("survey", survey);
  data.append("id", id);
  return server({
    method: "post",
    baseURL: "http://110.87.103.59:18086/farmfriend",
    url: "/land/update",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
};

/****
 * 农田取土记录list
 * id: 农田的id
 * ****/
export const getFieldSoil = function(landId) {
  return server({
    method: "get",
    baseURL: "http://110.87.103.59:18086/farmfriend",
    url: "/soilrecord/getList",
    params: {
      landId
    }
  });
};

/****
 * 农田新增取土记录
 * land_id: 农田的id
 * soil_code: 取土编号
 * soil_date: 取土时间
 * ****/
export const postAddFieldSoil = function(landId, soilDate, soilCode) {
  return server({
    method: "post",
    baseURL: "http://110.87.103.59:18086/farmfriend",
    url: "/soilrecord/save",
    data: {
      land_id: landId,
      soil_date: soilDate,
      soil_code: soilCode
    }
  });
};

/*
 * 农田新增作物  /landCrop/selectLandCropList
 */
export const getLandCropList = function(fieldID) {
  return server({
    method: "get",
    baseURL: "http://110.87.103.59:18086",
    url: "/farmfriend/landCrop/selectLandCropList",
    params: {
      land_id: fieldID
    }
  });
};

// 当前所有种植的作物
export const getCropListAll = function() {
  return server({
    method: "get",
    baseURL: "http://110.87.103.59:18086",
    url: "/farmfriend/crop/getAllCrop"
  });
};

// 根据角色查询种养殖数量
export const landStatistic = function() {
  return server({
    url: "http://110.87.103.59:18086/farmfriend/land/statistic",
    method: "GET"
  })
}

// 查询今日待办(首页)
export const landtodo = function() {
  return server({
    url: "http://110.87.103.59:18086/farmfriend/landCrop/todo"
  })
}
// 查询获取地块作物列表(未收获)/地块详情
export const landListNew = function(landId) {
  return server({
    url: `http://110.87.103.59:18086/farmfriend/landCrop/selectLandCropListNew?landId=${landId}`
  })
}